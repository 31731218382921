import React, { useState, useRef, useEffect, useMemo } from "react";
import styled from "styled-components";
import {
  AiOutlineCaretDown,
  AiOutlineCaretUp,
  AiOutlinePlus,
  AiOutlineTags,
} from "react-icons/ai";
import { NoDataContainer } from "./common/common-components";
import {
  createTag,
  setSessionTags,
  removeSessionTags,
} from "../utils/api/api-consumer";

const DropdownContainer = styled.div`
  position: relative;
`;

const DropdownButton = styled.div`
  //   padding: 5px 8px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: #515151;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  height: 32px;
`;

const DropdownContent = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  background-color: #fff;
  white-space: nowrap; // Prevent wrapping
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1002;
  width: auto; // Allow width to expand based on content
  max-height: 200px;
  min-width: 200px;
  overflow-y: auto;
  top: 140%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
`;

const TagItem = styled.label`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
  color: #fff;
`;

const CircleIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
`;

const TagDetails = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const DropdownIcon = styled.span`
  margin-left: 10px;
  font-size: 12px;
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0)")};
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
`;

const SelectedTagsContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  padding: 0px;
`;

const RoundedRectangle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 8px;
  width: 65%;
`;

const Text = styled.p`
  margin: 0;
  font-size: 10px;
`;

const ColouredCircle = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-left: 10px;
`;

const CreateTagInput = styled.input`
  padding: 10px 10px;
  border: none;
  outline: none;
  width: 100%;
  box-sizing: border-box;
`;

const InputDivider = styled.div`
    border-bottom: 1px solid #d1d1d1;
    margin: 0;
    width: 100%
    padding: 0px;
`;

const mockColors = [
  { label: "Grey", color: "#D3D3D3" },
  { label: "Dark Grey", color: "#A9A9A9" },
  { label: "Purple", color: "#6A5ACD" },
  { label: "Teal", color: "#008080" },
  { label: "Green", color: "#008000" },
  { label: "Yellow", color: "#FFFF00" },
  { label: "Orange", color: "#FFA500" },
  { label: "Pink", color: "#FFC0CB" },
  { label: "Red", color: "#FF0000" },
];

const TagData = ({
  sessionId,
  selectedItem,
  setSelectedItem,
  tags,
  setTags,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTagInput, setSearchTagInput] = useState(""); // New state for search input
  const [isCreating, setIsCreating] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown
  const inputRef = useRef(null); // Create a ref for the input

  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const filteredTags = useMemo(() => {
    return tags?.filter((tag) =>
      tag.label.toLowerCase().includes(searchTagInput.toLocaleLowerCase())
    );
  }, [tags, searchTagInput]);

  const toggleDropdown = () => {
    setIsOpen((prev) => {
      const newState = !prev;
      if (newState) {
        setTimeout(() => {
          inputRef.current.focus(); // Focus the input after dropdown is open
        }, 0); // Delay to ensure dropdown is rendered
      } else {
        setSearchTagInput("");
        setIsCreating(false);
      }
      return newState;
    });
  };

  // selectedTags = { sessionId: [{tag1}, {tag2}] }

  const handleTagChange = async (tag) => {
    const tags = selectedItem[sessionId];
    if (tags?.some((formattedTag) => formattedTag.label === tag.label)) {
      const newTags = tags.filter((newTag) => newTag.label !== tag.label);
      setSelectedItem({ ...selectedItem, [sessionId]: newTags });
      await removeSessionTags(sessionId, tag.id);
    } else {
      // if no tags, create array, else, append
      const newTags = selectedItem[sessionId]
        ? [...selectedItem[sessionId], tag]
        : [tag];
      setSelectedItem({ ...selectedItem, [sessionId]: newTags });
      // update in db
      await setSessionTags(sessionId, tag.id);
      // await removeSessionTags(sessionId, tag.id);
    }
  };

  const handleColorSelect = async (color) => {
    const barId = query.get("barId");
    await createTag(barId, searchTagInput, color);
    setIsCreating(false);
    setIsOpen(false);
    handleTagChange({ label: searchTagInput, color });
    setSearchTagInput("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTagInput("");
        setIsCreating(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton onClick={toggleDropdown}>
        {!(selectedItem[sessionId] && selectedItem[sessionId].length) ? (
          <>
            Select tags...
            <DropdownIcon>
              {isOpen ? (
                <AiOutlineCaretUp />
              ) : (
                <AiOutlineCaretDown style={{ marginTop: "1px" }} />
              )}
            </DropdownIcon>
          </>
        ) : (
          <SelectedTagsContainer>
            <RoundedRectangle>
              <Text>{selectedItem[sessionId][0].label}</Text>
              <ColouredCircle color={selectedItem[sessionId][0].color} />
            </RoundedRectangle>
            {selectedItem[sessionId].length > 1 ? (
              <Text style={{ marginLeft: "10px" }}>
                +{selectedItem[sessionId].length - 1}
              </Text>
            ) : null}
          </SelectedTagsContainer>
        )}
      </DropdownButton>
      <DropdownContent isOpen={isOpen}>
        <CreateTagInput
          ref={inputRef}
          placeholder="Add tag..."
          value={searchTagInput} // Bind the input value to searchInput state
          onChange={(e) => setSearchTagInput(e.target.value)} // Update searchInput on change
        />
        <InputDivider />
        {!isCreating && !filteredTags?.length && searchTagInput ? (
          <TagItem
            style={{ paddingLeft: "14px" }}
            onClick={() => setIsCreating(true)}
          >
            <AiOutlinePlus style={{ marginRight: "10px" }} />
            Create tag: {searchTagInput}
          </TagItem>
        ) : null}
        {isCreating &&
          mockColors.map((color) => (
            <TagItem onClick={() => handleColorSelect(color.color)}>
              <TagDetails>
                <span style={{ fontSize: "14px", color: "#515151" }}>
                  {color.label}
                </span>
                <CircleIndicator
                  style={{ backgroundColor: color.color }}
                ></CircleIndicator>
              </TagDetails>
            </TagItem>
          ))}
        {!isCreating &&
          filteredTags
            ?.sort((a, b) => {
              const isSelectedA =
                selectedItem[sessionId] &&
                selectedItem[sessionId]
                  .map((filteredTag) => filteredTag.label)
                  .includes(a.label);
              const isSelectedB =
                selectedItem[sessionId] &&
                selectedItem[sessionId]
                  .map((filteredTag) => filteredTag.label)
                  .includes(b.label);
              return isSelectedB - isSelectedA;
            })
            .map((tag) => (
              <TagItem key={tag.label}>
                <Checkbox
                  type="checkbox"
                  checked={
                    selectedItem[sessionId] &&
                    selectedItem[sessionId]
                      .map((filteredTag) => filteredTag.label)
                      .includes(tag.label)
                  }
                  onChange={() => handleTagChange(tag)}
                />
                <TagDetails>
                  <span style={{ fontSize: "14px", color: "#515151" }}>
                    {tag.label}
                  </span>
                  <CircleIndicator
                    style={{ backgroundColor: tag.color }}
                  ></CircleIndicator>
                </TagDetails>
              </TagItem>
            ))}
        {!filteredTags?.length && !searchTagInput.length && (
          <NoDataContainer>
            <AiOutlineTags />
            No Tags
          </NoDataContainer>
        )}
      </DropdownContent>
    </DropdownContainer>
  );
};

export default TagData;
