import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Divider } from "./common-components";

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 20px;
  color: #656565;
  width: 100%;
  text-align: left;
  font-weight: 600;
`;

const InputLabel = styled.div`
  font-size: 16px;
  margin: auto;
  width: 95%;
  text-align: left;
  color: #515151;
`;

const Input = styled.input`
  width: 91%;
  padding: 8px;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const ModalMessage = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  color: #515151;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 40px;
`;

const Button = styled.button`
  padding: 5px 20px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  min-width: 80px;

  ${(props) =>
    props.variant === "back" &&
    `
    background-color: white;
    color: #515151;;
    border: 1px solid #ccc;
  `}

  ${(props) =>
    props.variant === "End" &&
    `
    background-color: #CD1B1B;
    color: white;
  `}

  ${(props) =>
    props.variant === "Start" &&
    `
    background-color: #184730;
    color: white;
  `}
`;

const BaseModal = ({
  onClose,
  proceedType,
  type,
  copy,
  inputText,
  suggestedInput,
  title,
  proceedMethod,
}) => {
  const modalRef = useRef(null); // Create a ref for the dropdown
  const [inputValue, setInputValue] = useState(suggestedInput || ""); // State to maintain the input value

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  return (
    <ModalOverlay>
      <ModalContent ref={modalRef}>
        <Title>{title}</Title>
        <Divider />
        {type === "text" && <ModalMessage>{copy}</ModalMessage>}
        {type === "input" && (
          <>
            <InputLabel>{inputText}</InputLabel>
            <Input
              id="name"
              type="text"
              onChange={handleInputChange}
              value={suggestedInput}
            />
          </>
        )}
        <ButtonGroup>
          <Button variant="back" onClick={() => onClose(false)}>
            Back
          </Button>
          <Button
            variant={proceedType}
            onClick={() => {
              onClose(false);
              proceedMethod(inputValue);
            }}
          >
            {proceedType}
          </Button>
        </ButtonGroup>
      </ModalContent>
    </ModalOverlay>
  );
};

export default BaseModal;
