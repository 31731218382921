import React, { useState, useEffect, useRef } from "react";
import {
  PageContainer,
  Header,
  Divider,
  ButtonGroup,
  Button,
  InsideButton,
} from "./common/common-components";
import { AiOutlinePlusCircle, AiOutlineRight } from "react-icons/ai";
import styled from "styled-components";
import TagFilter from "./TagFilter";
import TagData from "./TagData";
import DatePicker from "./DatePicker";
import BaseModal from "./common/BaseModal";
import { createSession, getSessions, getTags } from "../utils/api/api-consumer";
import { useNavigate } from "react-router";
import { useUser } from "../context/UserContext";
import * as moment from "moment";
import { GrCoatCheck } from "react-icons/gr";

const SessionTableContainer = styled.div`
  background-color: #fff;
  width: 60vw;
  height: calc(
    100vh - 150px
  ); // Adjust this value based on your header/footer height
  border-radius: 10px;
  border: 1px solid #ccc;
  margin: auto;
  // overflow: auto;
`;

const FullTableHeader = styled.div`
  position: sticky; // Make the table header sticky
  top: 0px; // Use dynamic height
  z-index: 100;
`;

const TopTableHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  height: 60px;
  position: sticky; // Make the table header sticky
  top: 0px; // Use dynamic height
  background-color: #fff;
  z-index: 1000;
  border-radius: 10px;
`;

const Title = styled.h3`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
`;

const RightHeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
`;

const TableContainer = styled.div`
  max-height: calc(100% - ${(props) => props.headerHeight}px);
  overflow-y: scroll;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #515151;
  max-height: 80%;
`;

const TableRow = styled.tr`
  border-top: 1px solid #eee;
  position: relative;
`;

const TableHeaderContainer = styled.div`
  width: 100%;
  box-shadow: inset 0 1px 0 #ccc, inset 0 -1px 0 #ccc; // Create inner top and bottom borders
  background-color: #f0f0f0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  // position: sticky; // Make the table header sticky
  // top: ${(props) => props.headerHeight}px; // Use dynamic height
`;

const TableHeader = styled.div`
  text-align: center;
  color: #a1a1a1;
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  width: 100px; // Set a fixed width for the header
`;

const TableData = styled.td`
  padding: 20px 10px;
  font-size: 14px;
  width: 100px; // Set a fixed width for the data cells
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButton = styled.button`
  background-color: transparent;
  color: #a1a1a1;
  border: none;
  cursor: pointer;

  &:hover {
    color: #515151; // Use dynamic height
    transition: ease-in-out 0.3s;
  }
`;

const ClearFiltersButton = styled.div`
  background-color: white;
  color: #a1a1a1;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  font-size: 10px;

  &:hover {
    color: #515151;
    transition: ease-in-out 0.3s;
  }
`;

const NoDataTableContainer = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  color: #a1a1a1;
  font-size: 14px;
`;

const Sessions = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [sessionSelectedTags, setSessionSelectedTags] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isNewSessionOpen, setIsNewSessionOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [headerHeight, setHeaderHeight] = useState(0); // State to store header height
  const [sessions, setSessions] = useState([]); // Add state for sessions
  const { barId } = useUser();

  const panelHeaderRef = useRef(null); // Create a ref for the PanelHeader

  const handleClearFilters = () => {
    setSelectedTags([]);
    setStartDate(null);
    setEndDate(null);
  };

  const navigate = useNavigate();

  const handleNewSession = async (name) => {
    if (!name) {
      console.log("No name provided");
      return;
    }

    if (!barId) {
      console.log("No barId, cannot create session.");
      return;
    }

    const response = await createSession(barId, name);
    if (!response.sessionId) {
      console.log("Error when creating session.");
      return;
    }

    navigate(`/test/?sessionId=${response.sessionId}`);
  };

  const handleJoinSession = async (sessionId) => {
    if (!sessionId) {
      console.log("No sessionId provided");
      return;
    }

    navigate(`/test/?sessionId=${sessionId}`);
  };

  useEffect(() => {
    const fetchSessions = async () => {
      if (!barId) {
        console.log("No barId, cannot get sessions.");
        return;
      }
      const allSessions = await getSessions(barId);
      setSessions(allSessions); // Set the fetched sessions to state

      if (!allSessions) {
        return;
      }

      const tagsBySession = {};
      for (const session of allSessions) {
        if (!(session.id in tagsBySession)) {
          tagsBySession[session.id] = [];
        }
        if (session.tags) {
          tagsBySession[session.id].push(...session.tags);
        }
      }

      setSessionSelectedTags(tagsBySession);
    };

    const fetchTags = async () => {
      if (!barId) {
        console.log("No barId, cannot get tags.");
        return;
      }
      const allTags = await getTags(barId);
      setTags(allTags); // Set the fetched sessions to state
    };

    fetchSessions();
    fetchTags();
  }, [barId]); // Add query as a dependency

  useEffect(() => {
    if (panelHeaderRef.current) {
      setHeaderHeight(panelHeaderRef.current.offsetHeight); // Set height based on ref
    }
  }, [panelHeaderRef]);

  return (
    <PageContainer>
      {isNewSessionOpen && (
        <BaseModal
          onClose={setIsNewSessionOpen}
          type="input"
          proceedType="Start"
          inputText="Name"
          suggestedInput={new Date().toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
          title="Start Session"
          proceedMethod={handleNewSession}
        />
      )}
      <Header width={60}>
        <span style={{ color: "#656565" }}>Sessions</span>
        <ButtonGroup>
          <Button primary onClick={() => setIsNewSessionOpen(true)}>
            <InsideButton>
              <AiOutlinePlusCircle style={{ marginRight: "8px" }} />
              New Session
            </InsideButton>
          </Button>
        </ButtonGroup>
      </Header>

      <Divider width={60} />

      <SessionTableContainer>
        <FullTableHeader ref={panelHeaderRef}>
          <TopTableHeader>
            <Title>100 SESSIONS</Title>
            <RightHeaderContainer>
              {(selectedTags.length || (startDate && endDate)) && (
                <ClearFiltersButton onClick={handleClearFilters}>
                  Clear filters
                </ClearFiltersButton>
              )}
              <TagFilter
                tags={tags}
                selectedItem={selectedTags}
                setSelectedItem={setSelectedTags}
              />
              <DatePicker
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </RightHeaderContainer>
          </TopTableHeader>

          <TableHeaderContainer>
            <TableHeader>Date</TableHeader>
            <TableHeader>Start Time</TableHeader>
            <TableHeader>End Time</TableHeader>
            <TableHeader>Tags</TableHeader>
            <TableHeader>Actions</TableHeader>
          </TableHeaderContainer>
        </FullTableHeader>

        <TableContainer headerHeight={headerHeight}>
          <Table>
            <tbody>
              {sessions &&
                sessions
                  .sort((a, b) => {
                    if (!a.completed_at && b.completed_at) return -1;
                    if (a.completed_at && !b.completed_at) return 1;
                    if (a.completed_at && b.completed_at) {
                      return new Date(b.created_at) - new Date(a.created_at);
                    }
                    return 0;
                  })
                  .filter((session) => {
                    const sessionTags =
                      sessionSelectedTags[session.id]?.map(
                        (tag) => tag.label
                      ) || [];
                    return (
                      selectedTags.some((tag) => sessionTags.includes(tag)) ||
                      !selectedTags.length
                    );
                  })
                  .map((row) => {
                    return (
                      <TableRow key={row.hangerId}>
                        <TableData>
                          {moment(row.created_at)
                            .subtract(5, "hours")
                            .format("MM/DD/YYYY")}
                        </TableData>
                        <TableData>
                          {moment(row.created_at)
                            .subtract(5, "hours")
                            .format("h:mm A")}
                        </TableData>
                        <TableData>
                          {row.completed_at
                            ? moment(row.completed_at)
                                .subtract(5, "hours")
                                .format("h:mm A")
                            : "-"}
                        </TableData>
                        <TableData style={{ padding: "0px 10px" }}>
                          <TagData
                            sessionId={row.id}
                            selectedItem={sessionSelectedTags}
                            setSelectedItem={setSessionSelectedTags}
                            tags={tags}
                            setTags={setTags}
                          />
                        </TableData>
                        <TableData>
                          {row.completed_at ? (
                            <ActionButtonContainer>
                              <ActionButton>View insights</ActionButton>
                              <AiOutlineRight style={{ color: "#a1a1a1" }} />
                            </ActionButtonContainer>
                          ) : (
                            <ActionButtonContainer
                              onClick={() => handleJoinSession(row.id)}
                            >
                              <ActionButton join>Join session</ActionButton>
                              <AiOutlineRight style={{ color: "#a1a1a1" }} />
                            </ActionButtonContainer>
                          )}
                        </TableData>
                      </TableRow>
                    );
                  })}
              {/* Repeat rows as needed */}
              <tr>
                <td
                  colSpan={5}
                  style={{ height: "200px", background: "transparent" }}
                />
              </tr>
            </tbody>
          </Table>
          {!sessions?.length && (
            <NoDataTableContainer>
              <GrCoatCheck />
              No Sessions
            </NoDataTableContainer>
          )}
        </TableContainer>
      </SessionTableContainer>
    </PageContainer>
  );
};

export default Sessions;
