// frontend/src/components/Sidebar.js
import React, { useState, useMemo } from "react";
import styled from "styled-components";
import {
  AiOutlineDashboard,
  AiOutlineFileText,
  AiOutlineBarChart,
  AiOutlineTag,
  AiOutlineSetting,
  AiOutlineUser,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import FullLogo from "../assets/checkit-new-full.png";
import SmallLogo from "../assets/checkit-new-small.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import BaseModal from "./common/BaseModal";
import { createSession } from "../utils/api/api-consumer";

const SidebarContainer = styled.div`
  width: 60px; /* Slim version width */
  height: 100vh;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  transition: margin-right 0.3s ease; /* Smooth transition for width */
  box-sizing: border-box;
  position: fixed; /* Fixed position */
  left: 0; /* Align to the left */
  top: 0; /* Align to the top */
  z-index: 1000; /* Ensure it sits above other content */

  &:hover {
    width: 200px; /* Expanded version width */
    justify-content: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a box shadow for depth */
  }
`;

const AnotherLogoContainer = styled.div`
  min-height: 70px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const SmallLogoImage = styled.img`
  width: 30px; /* Adjust size as needed */
  height: auto;

  ${SidebarContainer}:hover & {
    display: none; /* Hide in expanded version */
  }
`;

const FullLogoImage = styled.img`
  width: 80%; /* Adjust size as needed */
  height: auto;
  display: none; /* Hidden in slim version */

  ${SidebarContainer}:hover & {
    display: block; /* Show in expanded version */
  }
`;

const NewSessionButton = styled.button`
  background-color: #184730;
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: margin-right 0.3s ease;
  display: none; /* Hidden in slim version */
  min-width: 150px;
  height: 30px;

  ${SidebarContainer}:hover & {
    display: flex; /* Show in expanded version */
    justify-content: center;
    align-items: center;
  }
`;

const SlimNewSessionButton = styled.button`
  background-color: #184730;
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: margin-right 0.3s ease;
  opacity: 1; /* Show in slim version */
  width: 30px;
  height: 30px;
  margin-bottom: 20px;

  display: flex; /* Show in expanded version */
  justify-content: center;
  align-items: center;

  ${SidebarContainer}:hover & {
    display: none; /* Show in expanded version */
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
`;

const MenuItem = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  color: #656565;
  cursor: pointer;
  position: relative;
  min-height: 39px;
  box-sizing: border-box;
  margin: 0; /* Ensure no margin is applied */

  &:hover {
    color: #184730;
  }
`;

const MenuIcon = styled.div`
  display: flex;
  // align-items: center;
  // justify-content: center;
  transition: margin-right 0.3s ease;
`;

const MenuText = styled.span`
  display: none;
  transition: margin-right 0.3s ease;
  white-space: nowrap; /* Prevent text wrapping */
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 0px;

  ${SidebarContainer}:hover & {
    display: block; /* Show text on hover */
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 10px;
  align-items: center;
`;

const UserInfoSquare = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex; /* Use flex to center content */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  color: #626262;
`;

const UserInfo = styled.div`
  text-align: left;
  transition: margin-right 0.3s ease;
  display: none;
  margin-left: 10px;
  color: #626262;
  ${SidebarContainer}:hover & {
    display: block; /* Show in expanded version */
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent */
  width: 80%;
`;

const Sidebar = () => {
  const [isNewSessionOpen, setIsNewSessionOpen] = useState(false);

  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const navigate = useNavigate();

  const handleNewSession = async (name) => {
    if (!name) {
      console.log("No name provided");
      return;
    }

    const barId = query.get("barId");
    if (!barId) {
      console.log("No barId, cannot create session.");
      return;
    }

    const response = await createSession(barId, name);
    if (!response?.sessionId) {
      console.log("Error when creating session.");
      return;
    }

    navigate(`/test/?sessionId=${response.sessionId}`);
  };
  return (
    <>
      {isNewSessionOpen && (
        <BaseModal
          onClose={setIsNewSessionOpen}
          type="input"
          proceedType="Start"
          inputText="Name"
          suggestedInput={new Date().toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
          title="Start Session"
          proceedMethod={handleNewSession}
        />
      )}
      <SidebarContainer>
        <AnotherLogoContainer>
          <LogoContainer>
            <SmallLogoImage src={SmallLogo} alt="Small Logo" />
            <FullLogoImage src={FullLogo} alt="Full Logo" />
          </LogoContainer>
        </AnotherLogoContainer>
        <NewSessionButton onClick={() => setIsNewSessionOpen(true)}>
          <AiOutlinePlusCircle style={{ marginRight: "10px" }} /> New Session
        </NewSessionButton>
        <SlimNewSessionButton>
          <AiOutlinePlusCircle />
        </SlimNewSessionButton>
        <MenuItem>
          <MenuIcon>
            <AiOutlineDashboard />
          </MenuIcon>
          <MenuText>Dashboard</MenuText>
        </MenuItem>
        <StyledLink to="sessions">
          <MenuItem>
            <MenuIcon>
              <AiOutlineFileText />
            </MenuIcon>
            <MenuText>Sessions</MenuText>
          </MenuItem>
        </StyledLink>
        <MenuItem>
          <MenuIcon>
            <AiOutlineBarChart />
          </MenuIcon>
          <MenuText>Revenue</MenuText>
        </MenuItem>
        <MenuItem>
          <MenuIcon>
            <AiOutlineTag />
          </MenuIcon>
          <MenuText>Tags</MenuText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <MenuIcon>
            <AiOutlineSetting />
          </MenuIcon>
          <MenuText>Settings</MenuText>
        </MenuItem>
        <MenuItem>
          <MenuIcon>
            <AiOutlineUser />
          </MenuIcon>
          <MenuText>Roles</MenuText>
        </MenuItem>
        <UserInfoContainer>
          <UserInfoSquare>P</UserInfoSquare>
          <UserInfo>
            <div style={{ fontSize: "12px" }}>Paris Texas</div>
            <div style={{ fontSize: "10px", marginTop: "3px" }}>
              michael@paristexas.com
            </div>
          </UserInfo>
        </UserInfoContainer>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
