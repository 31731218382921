export const hangerIdFormatter = (hid) => {
  const splitId = hid.split(" ");
  return `${splitId[0]}-${splitId[2]}`;
};

export const determineCoatStatus = (coats) => {
  const statuses = {
    fulfilled: [],
    retrieved: [],
    requested: [],
    checked: [],
  };

  coats.forEach((coat) => {
    const formattedCoat = {
      hangerId: coat.hanger_id,
      last4: coat.phone_number.slice(-4),
    };
    if (coat.request_fulfilled_at) {
      statuses.fulfilled.push(formattedCoat);
    } else if (coat.triaged_at) {
      statuses.retrieved.push(formattedCoat);
    } else if (coat.requested_at) {
      statuses.requested.push(formattedCoat);
    } else {
      statuses.checked.push(formattedCoat);
    }
  });

  return statuses;
};
